import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import './BhajanDetails.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesUp, faAnglesLeft, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

const BhajanDetails = ({ selectedBhajan, clearSelectedBhajan }) => {
  const navigate = useNavigate();
  const [fontSize, setFontSize] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const scrollButton = document.querySelector('.scroll-to-top');
      if (scrollButton) {
        if (window.scrollY > 200) {
          scrollButton.style.display = 'block';
        } else {
          scrollButton.style.display = 'none';
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (!selectedBhajan) {
    return null;
  }

  const { index, bhajan, lyrics } = selectedBhajan;

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const goBack = () => {
    navigate('/');
    clearSelectedBhajan();
  }

  const fontPlus = () => {
    setFontSize(prevSize => Math.min(prevSize + 0.1, 4));
  }

  const fontMinus = () => {
    setFontSize(prevSize => Math.max(prevSize - 0.1, 0.5));
  }

  return (
    <div className="bhajan-details-container">
      <div className="details-content" style={{ fontSize: `${fontSize}rem` }}>
        <h2 className="bhajan-title">
          <span className='index'> #{index} </span>
          {bhajan}
        </h2>
        <ReactMarkdown className="lyrics-container">{lyrics}</ReactMarkdown>
      </div>
      <div className="scroll-to-top" onClick={scrollToTop}>
        <FontAwesomeIcon icon={faAnglesUp} />
      </div>
      <div className="go-back" onClick={goBack}>
        <FontAwesomeIcon icon={faAnglesLeft} />
      </div>
      <div className="font-plus" onClick={fontPlus}>
        <FontAwesomeIcon icon={faPlus} />
      </div>
      <div className="font-minus" onClick={fontMinus}>
        <FontAwesomeIcon icon={faMinus} />
      </div>
    </div>
  );
};


export default BhajanDetails;